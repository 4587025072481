<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :subtitle="$t('Reports')"
      :title="$t('Reports')"
      icon="mdi-chart-areaspline"
      :side-navigation="navigation"
      navigation-route="statistics"
      v-model="navigationData"

      :filters="$store.state.statistics.pageData.filters || []"
      :filters-input="$store.state.statistics.filtersData || {}"
      @filter="$store.state.statistics.filtersData = $event"

  >
    <router-view></router-view>
  </dash-page-new>
</template>

<script>
export default {
  name: "BusinessDashboardStatistics",
  data() {
    return {
      navigationData : null
    }
  },
  computed : {
    navigation() {
      let items = [
        { text : this.$t('Courses'),  value : 'courses'   , path : "courses" , icon : 'mdi-school-outline' ,  permission:'MODULE_WESTUDY', },
        // { text : this.$t('Students'), value : 'courses'  , path : "students" , icon : 'mdi-school-outline' ,  permission:'MODULE_WESTUDY', },
      ]
      return items
    }
  }
}
</script>

<style scoped>

</style>